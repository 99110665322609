var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "법규별 규제사항" },
          },
          [
            _c(
              "template",
              { slot: "card-detail" },
              _vm._l(_vm.header, function (item, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "col-12 divchklaw" },
                  [
                    _c("div", { staticClass: "lawspan" }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                    _vm._l(item.child, function (_item, _idx) {
                      return _c("q-checkbox", {
                        key: _idx,
                        staticClass: "customqcbox",
                        attrs: {
                          dense: "",
                          color: "orange-7",
                          label: _item.label,
                          "true-value": "Y",
                          "false-value": "N",
                          value: _item.vals,
                          disable: true,
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }